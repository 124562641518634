import React from 'react';
import styles from '../../styles/TopCampaigns.module.scss';
import {useState} from 'react';
import {ArrowUp} from '../../assets/icons';
import Dropdown from '../../components/Dropdown/Dropdown';
import FidelityStructure from '../FidelityStructure/FidelityStructure';
import DownloadDropdown from '../../components/Dropdown/DownloadDropdown';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import axios from 'axios';
import {useQuery} from 'react-query';
import Pagination from 'react-js-pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../../assets/images/fidelityLoader.gif';
const API_ENDPOINT = 'https://dashboard.perxclm3.com/api.php';

const TopCampaigns = () => {
  const [isOpen, setIsOpen] = useState(false);

  const year = [
    {value: 2024},
    {value: 2023},
    {value: 2022},
    {value: 2021},
    {value: 2020},
    {value: 2019},
    {value: 2018},
    {value: 2017},
    {value: 2016},
    {value: 2015},
  ];
  const monthData = [
    {value: 'Jan', monthNumber: '01'},
    {value: 'Feb', monthNumber: '02'},
    {value: 'Mar', monthNumber: '03'},
    {value: 'Apr', monthNumber: '04'},
    {value: 'May', monthNumber: '05'},
    {value: 'Jun', monthNumber: '06'},
    {value: 'Jul', monthNumber: '07'},
    {value: 'Aug', monthNumber: '08'},
    {value: 'Sep', monthNumber: '09'},
    {value: 'Oct', monthNumber: '10'},
    {value: 'Nov', monthNumber: '11'},
    {value: 'Dec', monthNumber: '12'},
  ];

  const formatCurrency = value => {
    return value?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'Naira',
    });
  };

  const priceBodyTemplate = product => {
    return formatCurrency(product?.price);
  };

  // //////////Top Campaigns API CALL///////////////////////

  const [selectedYear, setSelectedYear] = useState(year[0].value);
  const topCampaignDate = new Date();
  const topCampaignMonth = topCampaignDate.getMonth();
  const [topCampaignMonthNumber, setTopCampaignMonthNumber] =
    useState(topCampaignMonth);

  const topCampaignMonthName = monthData[topCampaignMonthNumber];
  const [selectedMonth, setSelectedMonth] = useState(
    topCampaignMonthName?.value,
  );

  const topCampaignQueryMonth = monthData.filter(
    each => each.value === selectedMonth,
  );

  const fetchTopCampaigns = async (year = '', month = '') => {
    const params = {
      api: 'get_top_campaign_report',
      year: year || undefined,
      month: month || undefined,
    };
    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {Token: process.env.REACT_APP_FIDELITY_TOKEN},
    });

    return response?.data?.data;
  };

  const {data, isLoading, isError} = useQuery(
    ['Top Campaigns', selectedYear, topCampaignQueryMonth],
    () =>
      fetchTopCampaigns(selectedYear, topCampaignQueryMonth[0]?.monthNumber),
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const cols = [
    {field: 'campaign_name', header: 'Transaction Type'},
    {field: 'reward_points', header: 'Points Earned'},
  ];
  return (
    <FidelityStructure>
      <div className={styles.tableSection}>
        <div className={styles.topCustomer}>
          <div className={styles.reportTop}>
            <h4>Top Transaction Type</h4>
            <p>view all top transaction type in the program</p>
          </div>
          <div className={styles.dropdownOutterCalendar}>
            <div className={styles.dropdownCalendar}>
              <Dropdown
                data={year}
                rangeValue={selectedYear}
                setRangeValue={setSelectedYear}
              />
              <Dropdown
                data={monthData}
                rangeValue={selectedMonth}
                setRangeValue={setSelectedMonth}
              />
            </div>
            <DownloadDropdown products={currentItems} cols={cols} />
          </div>

          <div className={styles.tableContainer}>
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <img src={Loader} alt="loader" />
              </div>
            ) : (
              <DataTable
                value={currentItems}
                sortable
                sortField="transaction_amount"
                sortOrder={-1}
                className={styles.mainTable}>
                <Column
                  field="campaign_name"
                  header="Transaction Type"
                  sortable
                  style={{width: '20%'}}></Column>
                {/* <Column
                  field="transaction_amount"
                  header="Transaction Amount"
                  sortable
                  style={{width: '20%'}}></Column> */}
                <Column
                  field="reward_points"
                  header="Points Earned"
                  //   body={priceBodyTemplate}
                  sortable
                  style={{width: '20%'}}></Column>
              </DataTable>
            )}
          </div>
          <div className={styles.paginationContainer}>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={data?.length}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
      </div>
    </FidelityStructure>
  );
};

export default TopCampaigns;
