import React from 'react';
import {useState, useEffect} from 'react';
import styles from '../../styles/Navbar.module.scss';
import perx from '../../assets/images/Perx-logo.png';
import profile from '../../assets/images/profile.jpg';
import {Hamburger} from '../../assets/icons';
import {Link} from 'react-router-dom';

const Navbar = ({setMobileNav, mobileNav}) => {
  const [navText, setNavText] = useState('Dashboard');
  const [navPText, setNavPText] = useState(
    'View all statistics on the dashboard',
  );
  const currentURL = window.location.href;

  useEffect(() => {
    const linkValue = currentURL.split('/')?.length - 1;
    const currentPage = currentURL.split('/')[linkValue];

    if (currentPage === 'dashboard') {
      setNavText('Dashboard');
      setNavPText('View all statistics on the dashboard');
    } else {
      setNavText('Report');
      setNavPText('View all report here');
    }
  }, [currentURL]);

  // const [isResponsive, setIsResponsive] = useState(false);

  const toggleResponsiveClass = () => {
    setMobileNav(!mobileNav);
  };

  return (
    <div className={styles.topnav}>
      <div className={styles.navContainer}>
        <div className={styles.navTextContainer}>
          <h4 className={styles.navText1}>{navText}</h4>
          <p className={styles.navText2}>{navPText}</p>
        </div>

        <div className={styles.profileContainer}>
          <div className={styles.profile}>
            <div className={styles.profilePicture}>
              <img src={profile} alt="profile" />
            </div>
            <p className={styles.profileName}>Hello Admin</p>
          </div>
          <Link to="https://greenrewardsdemo.perxclm3.com/PerXCLM.php">
            <button className={styles.perxButton}>
              Go to PerX <img src={perx} alt="perx" />
            </button>
          </Link>

          <div className={styles.Hamburger} onClick={toggleResponsiveClass}>
            <Hamburger />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
