import React from 'react';
import styles from '../../styles/CampaignBreakdown.module.scss';
import {useState} from 'react';
import {ArrowUp} from '../../assets/icons';
import Dropdown from '../../components/Dropdown/Dropdown';
import FidelityStructure from '../FidelityStructure/FidelityStructure';
import axios from 'axios';
import {useQuery} from 'react-query';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Pagination from 'react-js-pagination';
import Loader from '../../assets/images/fidelityLoader.gif';
import DownloadDropdown from '../../components/Dropdown/DownloadDropdown';
import {useEffect} from 'react';
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   // CartesianGrid,
//   Tooltip,
//   // Legend,
// } from 'recharts';
const API_ENDPOINT = 'https://dashboard.perxclm3.com/api.php';

const CampaignBreakdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('Download');
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectedValue = value => {
    setSelectedType(value);
  };

  const DropdownYear = [
    {value: 2024},
    {value: 2023},
    {value: 2022},
    {value: 2021},
    {value: 2020},
    {value: 2019},
    {value: 2018},
    {value: 2017},
    {value: 2016},
    {value: 2015},
  ];

  const monthData = [
    {value: 'Jan', monthNumber: '01'},
    {value: 'Feb', monthNumber: '02'},
    {value: 'Mar', monthNumber: '03'},
    {value: 'Apr', monthNumber: '04'},
    {value: 'May', monthNumber: '05'},
    {value: 'Jun', monthNumber: '06'},
    {value: 'Jul', monthNumber: '07'},
    {value: 'Aug', monthNumber: '08'},
    {value: 'Sep', monthNumber: '09'},
    {value: 'Oct', monthNumber: '10'},
    {value: 'Nov', monthNumber: '11'},
    {value: 'Dec', monthNumber: '12'},
  ];

  const [barChartStyling, setBarChartStyling] = useState(1000);
  const [dataYear, setDataYear] = useState(DropdownYear[0]?.value);

  useEffect(() => {
    const handleResize = () => {
      const value = window.innerWidth;
      if (value > 888) {
        setBarChartStyling(value - 300);
      } else {
        setBarChartStyling(value - 50);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // ///////////////Campaign Breakdown API Call////////////////////////

  const [selectedYear, setSelectedYear] = useState(DropdownYear[0].value);
  const breakdownCampaignDate = new Date();
  const breakdownCampaignMonth = breakdownCampaignDate.getMonth();
  const [breakdownCampaignMonthNumber, setBreakdownCampaignMonthNumber] =
    useState(breakdownCampaignMonth);

  const breakdownCampaignMonthName = monthData[breakdownCampaignMonthNumber];
  const [selectedMonth, setSelectedMonth] = useState(
    breakdownCampaignMonthName?.value,
  );

  const breakdownCampaignQueryMonth = monthData.filter(
    each => each.value === selectedMonth,
  );

  const fetchBreakdownCampaigns = async (year = '', month = '') => {
    const params = {
      api: 'get_campaign_breakdown_report',
      year: year || undefined,
      month: month || undefined,
    };
    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {Token: process.env.REACT_APP_FIDELITY_TOKEN},
    });

    return response?.data?.data;
  };

  const {data, isLoading, isError} = useQuery(
    ['Breakdown Campaigns', selectedYear, breakdownCampaignQueryMonth],
    () =>
      fetchBreakdownCampaigns(
        selectedYear,
        breakdownCampaignQueryMonth[0]?.monthNumber,
      ),
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const cols = [
    {field: 'Campaign_name', header: 'Campaign name'},
    {field: 'product_code', header: 'Product Code'},
    {field: 'channel', header: 'Channel'},
    {
      field: 'total_incremental_amount',
      header: 'Total Incremental Income by Transaction Type',
    },
    {
      field: 'total_qualified_amount',
      header: 'Total Qualified Incremental Income By Campaign',
    },
    {
      field: 'total_non_qualified_amount',
      header: 'Total Non-qualified Incremental Income By Campaign',
    },
    {field: 'total_points_issued', header: 'Total Points Issued'},
    {
      field: 'total_points_issued_in_naira',
      header: 'Total Point Issued In Naira (₦)',
    },
    {field: 'net_incremental_income', header: 'Net Incremental Income In (₦)'},
    {field: 'gross_incremental_income', header: 'Gross Incremental Income (₦)'},
  ];

  //////////////////////////// CAMPAIGN TRANSACTION REPORT////////////////////////////////////

  ///////////////////////// Campaigns Transaction Report//////////////////////

  const [campaignTransaction, setCampaignTransaction] = useState(
    DropdownYear[0]?.value,
  );

  const campaignTransactionDate = new Date();
  const campaignTransactionCurrentMonth = campaignTransactionDate.getMonth();
  let campaignTransactionPreviousMonth;
  let campaignTransactionCurrentPreviousMonth;

  if (campaignTransactionCurrentMonth === 0) {
    campaignTransactionCurrentPreviousMonth = 11;
  } else {
    campaignTransactionCurrentPreviousMonth =
      campaignTransactionCurrentMonth - 1;
  }

  const [campaignTransactionMonthNumber, setCampaignTransactionMonthNumber] =
    useState(campaignTransactionCurrentPreviousMonth);

  const campaignTransactionMonthName =
    monthData[campaignTransactionMonthNumber];

  const [campaignTransactionMonth, setCampaignTransactionMonth] = useState(
    campaignTransactionMonthName?.value,
  );

  const campaignTransactionQueryMonth = monthData.filter(
    each => each.value === campaignTransactionMonth,
  );

  const fetchCampaignTransaction = async (year = '', month = '') => {
    const params = {
      api: 'get_campaign_transaction_report',
      year: year || undefined,
      month: month || undefined,
    };

    const response = await axios.get(API_ENDPOINT, {
      params,
      headers: {
        Token: process.env.REACT_APP_FIDELITY_TOKEN,
      },
    });
    return response?.data?.data;
  };

  const {
    data: campaignTransactionData,
    isLoading: campaignTransactionLoading,
    isError: campaignTransactionError,
  } = useQuery(
    [
      'Campaign Transaction Data',
      campaignTransaction,
      campaignTransactionQueryMonth,
    ],
    () =>
      fetchCampaignTransaction(
        campaignTransaction,
        campaignTransactionQueryMonth[0]?.monthNumber,
      ),
  );

  return (
    <FidelityStructure>
      <div className={styles.topCustomer}>
        <div className={styles.reportTop}>
          <h4>Transaction Type Breakdown</h4>
          <p>View all top transaction type in the program</p>
        </div>
        <div className={styles.dropdownOutterCalendar}>
          <div className={styles.dropdownCalendar}>
            <Dropdown
              data={DropdownYear}
              rangeValue={selectedYear}
              setRangeValue={setSelectedYear}
            />
            <Dropdown
              data={monthData}
              rangeValue={selectedMonth}
              setRangeValue={setSelectedMonth}
            />
          </div>
          <DownloadDropdown products={currentItems} cols={cols} />
        </div>

        <div className={styles.tableContainer}>
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <img src={Loader} alt="loader" />
            </div>
          ) : (
            <DataTable
              value={currentItems}
              sortable="true"
              sortField="product_code"
              sortOrder={-1}
              className={styles.mainTable}>
              <Column
                field="Campaign_name"
                header="Campaign Name"
                sortable="true"
                style={{width: '10%'}}></Column>
              <Column
                field="product_code"
                header="Product Code"
                sortable="true"
                style={{width: '10%'}}></Column>
              <Column
                field="channel"
                header="Channel"
                //   body={priceBodyTemplate}
                sortable="true"
                style={{width: '10%'}}></Column>
              <Column
                field="total_incremental_amount"
                header="Total Incremental Income by Transaction Type"
                //   body={priceBodyTemplate}
                sortable="true"
                style={{width: '10%'}}></Column>
              <Column
                field="total_qualified_amount"
                header="Total Qualified Incremental Income By Campaign"
                //   body={priceBodyTemplate}
                sortable="true"
                style={{width: '10%'}}></Column>
              <Column
                field="total_non_qualified_amount"
                header="Total Non-qualified Incremental Income By Campaign"
                //   body={priceBodyTemplate}
                sortable="true"
                style={{width: '10%'}}></Column>
              <Column
                field="total_points_issued"
                header="Total Points Issued"
                //   body={priceBodyTemplate}
                sortable="true"
                style={{width: '10%'}}></Column>
              <Column
                field="total_points_issued_in_naira"
                header="Total Point Issued In Naira (₦)"
                //   body={priceBodyTemplate}
                sortable="true"
                style={{width: '10%'}}></Column>
              <Column
                field="net_incremental_income"
                header="Net Incremental Income In (₦)"
                //   body={priceBodyTemplate}
                sortable="true"
                style={{width: '10%'}}></Column>
              <Column
                field="gross_incremental_income"
                header="Gross Incremental Income (₦)"
                //   body={priceBodyTemplate}
                sortable="true"
                style={{width: '10%'}}></Column>
            </DataTable>
          )}
        </div>

        <div className={styles.paginationContainer}>
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={data?.length}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
      <div className={styles.barChartContainer}>
        <div className={styles.barChartTopContainer}>
          <div className={styles.barChartTop}>
            <h4>Transaction Type Report</h4>
            <p>View all transaction type report</p>
          </div>
          <div className={styles.barChartYearAndMonth}>
            <Dropdown
              data={monthData}
              rangeValue={campaignTransactionMonth}
              setRangeValue={setCampaignTransactionMonth}
            />
            <Dropdown
              data={DropdownYear}
              rangeValue={dataYear}
              setRangeValue={setDataYear}
            />
          </div>
        </div>

        {campaignTransactionLoading ? (
          <div className={styles.loaderContainer}>
            <img src={Loader} alt="loader" />
          </div>
        ) : (
          <div>
            <div className={styles.campaignsBalanceContainer}>
              <div className={styles.campaignsInnerContainer}>
                <div className={styles.campaign1}>
                  <p>Total Transaction Type</p>
                  <h6>
                    {campaignTransactionData?.cards
                      ? parseInt(
                          campaignTransactionData?.cards[0]?.Point,
                        ).toLocaleString()
                      : '0'}
                  </h6>
                </div>

                <div className={styles.campaign4}>
                  <p>Highest Transaction Type</p>
                  <h6>
                    {campaignTransactionData?.cards
                      ? parseInt(
                          campaignTransactionData?.cards[1]?.Point,
                        ).toLocaleString()
                      : '0'}
                  </h6>
                  <p>{campaignTransactionData?.cards[1]?.CampName}</p>
                </div>
                <div className={styles.campaign5}>
                  <p>Lowest Transaction Type</p>
                  <h6>
                    {campaignTransactionData?.cards
                      ? parseInt(
                          campaignTransactionData?.cards[2]?.Point,
                        ).toLocaleString()
                      : '0'}
                  </h6>
                  <p>{campaignTransactionData?.cards[2]?.CampName}</p>
                </div>
              </div>
            </div>

            {/* {campaignTransactionData?.result ? (
              <BarChart
                width={barChartStyling}
                height={250}
                margin={{top: 5, right: 10, left: 20, bottom: 5}}
                data={campaignTransactionData?.result}>
                <XAxis dataKey="campName" />
                <YAxis />
                <Tooltip />

              
                <Bar dataKey="point" fill="#4FC143" />
              </BarChart>
            ) : (
              <div className={styles.nodataContainer}>
                <h1>No Data</h1>
              </div>
            )} */}
          </div>
        )}
      </div>
    </FidelityStructure>
  );
};

export default CampaignBreakdown;
